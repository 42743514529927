.note-container .link {
  text-decoration: none;
}

.note {
  transition: all 0.2s ease;
  /* opacity: 0.7; */
}

.note:hover {
  transition: all 0.2s ease;
  /* opacity: 1; */
  transform: scale(1.01) !important;
}
