.products .theme-toggle {
  position: fixed;
  top:10px;
  right: 10px;
}


.products .card {
  border: 0px;
  border-radius: 0px;
  background-color: rgba(191, 191, 191, 0.467)
}

.card-overlay{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  cursor: pointer;
}

/* position: 'absolute', height: '100%', width: '100%', backgroundColor: 'red', top:0 */

/* .products .card:hover {
  transform: scale(1.05);
} */