:root {
  --bg-color: #ffffff;
  --primary-color: #ffffff;
  --secondary-color: #000;
  --text-color: #000;
  --text-color-2: #000;
  --text-color-3: rgb(204, 0, 0);
  --overlay-color: rgb(255 255 255 / 70%);
  --scrolldown-filter: invert(0%);
}

[data-theme="dark"] {
  --bg-color: #212529;
  --primary-color: #212529;
  --secondary-color: #fff;
  --text-color: #fff;
  --text-color-2: #fff;
  --text-color-3: rgb(204, 0, 0);
  --overlay-color: rgb(12 12 12 / 63%);
  --scrolldown-filter: invert(80%);
}

body,
html {
  background-color: var(--bg-color);
}

.link-remove-underline {
  text-decoration: none;
}

.portfolio-container {
  height: 100%;
  background-color: #00000054;
}

.portfolio-container {
  margin: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 60px;
  border-left: 10px solid var(--primary-color);
  border-right: 10px solid var(--primary-color);
}

.portfolio-container ul {
  list-style: none;
}

.portfolio-container h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Marcellus;
}

a,
a:hover {
  color: var(--text-color);
}

p {
  word-break: break-word;
  hyphens: auto;
}

.ovhidden {
  overflow: hidden;
}

.text_2,
.text_2:hover {
  color: var(--text-color-2);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursor__dot div {
  z-index: 999999 !important;
}

.cursor__dot div:last-child {
  background-color: var(--text-color-3) !important;
}

.cursor__dot div:first-child {
  filter: invert(1);
  background-color: var(--overlay-color) !important;
}

.color_pr {
  color: var(--primary-color) !important;
}

.color_sec {
  color: var(--secondary-color);
}

/* CSS */
/* WebKit (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 12px; /* width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: var(--bg-color); /* color of the track */
}

::-webkit-scrollbar-thumb {
  background: var(--text-color); /* color of the scrollbar */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scrollbar when hovered */
}

/* Firefox */
/* Set the width of the scrollbar */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--text-color) var(--bg-color); /* color of the scrollbar */
}

/* Set the background color of the track */
*::-webkit-scrollbar-track {
  background: var(--bg-color);
}

/* Set the color of the scrollbar thumb */
*::-webkit-scrollbar-thumb {
  background-color: var(--text-color);
}

/* Set the color of the scrollbar thumb when hovered */
*::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
