.sec_sp {
  margin-bottom: calc(3rem + 5.128vw);
}

.table td,
.table th {
  color: var(--text-color);
}

.table .post {
  font-weight: normal;
  opacity: 0.7;
  font-size: 14px;
}

.t_border {
  border-color: var(--text-color) !important;
}

.progress-title {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  /* opacity: 0; */
  /* transform: translateY(-5px); */
  /* transition: all 0.2s ease-in; */

  /* white-space: nowrap;
    text-overflow: ellipsis; */
}

.progress {
  height: 5px;
  background: var(--secondary);
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 30px;
  overflow: visible;
}

.progress .progress-bar {
  position: relative;
  background: var(--text-color);
  animation: animate-positive 2s;
  overflow: visible;
  opacity: 0.9;
}

.progress .progress-value {
  position: absolute;
  top: -30px;
  right: 8px;
  font-size: 17px;
  font-weight: bold;
  font-style: italic;
  color: var(--text-color);
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0%;
  }
}

@keyframes animate-positive {
  0% {
    width: 0%;
  }
}

.section-title {
  font-size: 45px;
}

.service__title {
  padding: 8px 0;
  border-bottom: solid 2px var(--secondary-color);
}

.service-section .service-category-title {
  padding-bottom: 4px;
}

/*! CSS Used keyframes */

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

/* .table .company:hover .company-img {
    opacity: 1;
    transition: 0.3s ease-in-out;
} */

.table .company-img {
  height: 20px;
  margin-left: 10px;
  /* opacity: 0; */
  /* transition: 0.3s ease-in-out; */
}

/* .table .company-name {
  flex: 0
} */

.table .company {
  cursor: pointer;
}

.table .company-date {
  text-align: center;
  vertical-align: middle;
}

.about_skills {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.skill_logo {
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
}

.skill_logo_main {
  height: 70px;
  width: 70px;
  margin-bottom: 10px;
  border-radius: 18px;
  overflow: hidden;
}

.skill_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  max-width: 70px;
}

/* .skill_container:hover .progress-title{
    opacity: 1;
    transform: translateY(0px);
} */
