.not-found-container {
  text-align: center;
  margin-top: 100px;
}

.not-found-title {
  font-size: 36px;
  font-weight: bold;
  color: #e74c3c; /* Red color for the title */
}

.not-found-text {
  font-size: 18px;
  color: #555;
  margin-top: 20px;
}
