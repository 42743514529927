.reacttips-item-header {
  /* cursor: pointer; */
}

.reacttips-item p {
  margin: 0;
}

.reacttips-item .code {
  border: 0.5px solid var(--bs-code-color);
  color: var(--bs-code-color);
  border-radius: 5px;
  padding-bottom: 10px !important;
  min-height: 50px;
}

.reacttips-item .animate-container {
  position: relative;
  /* animation: fade-in 0.5s; */
}

.reacttips-item .copy {
  color: var(--secondary-color);
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  height: 2.5em;
  width: 2.5em;
  cursor: pointer;
  color: rgb(130, 130, 130);
  background-color: var(--primary-color);
  margin: 4px;
}

.reacttips .info {
  opacity: 0.5;
  font-size: 12px;
  text-align: end;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.copy-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary-color);
  padding: 10px;
  padding-bottom: 7px;
  padding-top: 7px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.copy-popup.show {
  opacity: 1;
}
