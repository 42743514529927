.theme_toggler {
    background: var(--primary-color);
    z-index: 999999999;
    left: 10px;
    background: var(--primary-color);
    display: flex;
    height: 50px;
    align-items: center;
    padding-left: 10px;
}

.theme_toggler svg {
    width: 2em;
    height: 2em;
}

.nav_ac .moon.show {
    animation: zoom-in 0.3s;
}

.nav_ac .moon.hide {
    animation: zoom-out 0.3s;
    transform: scale(0);
}

.nav_ac .sun.show {
    animation: zoom-in 0.3s;
}

.nav_ac .sun.hide {
    animation: zoom-out 0.3s;
    transform: scale(0);
}

@keyframes zoom-in {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes zoom-out {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

.nav_ac.themetoggler {
  cursor: pointer;
}